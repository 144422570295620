import * as React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Hero from "../../components/Hero/Hero"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ProjectLanding = ({ data }) => {
  const { index, projects } = data

  return (
    <Layout>
      <Seo
        title={index.metaTitle || index.heading}
        description={index.metaDescription}
      />
      <Hero
        heading={index.heading}
        headingTranslated={index.headingTranslated}
        subheading={index.subheading}
        text={index.text}
        image={index.image[0]}
      />
      <section className="py-28 md:py-56">
        <div className="container">
          <h2 className="text-2xl lg:text-4xl font-semibold mb-6 lg:mb-12">
            Explore our projects
          </h2>
          {projects.nodes.length > 0 && (
            <div className="grid grid-cols-12 gap-y-15 lg:gap-x-10 lg:gap-y-20">
              {projects.nodes.map(item => {
                const { id, uri, image, title, summary } = item

                return (
                  <article className="col-span-12 lg:col-span-6" key={id}>
                    <Link className="group" to={`/${uri}/`}>
                      <div className="aspect-w-3 aspect-h-2 mb-8">
                        <div className="rounded-br-3xl overflow-hidden mb-4 bg-black bg-opacity-25">
                          {image[0] && image[0].localFile && (
                            <GatsbyImage
                              className="w-full h-full object-cover"
                              image={getImage(image[0].localFile)}
                              alt={image[0].title}
                              style={{ position: "absolute" }} // To override Gatsby Image for aspect ratio
                            />
                          )}
                        </div>
                      </div>
                      <div className="lg:pr-10">
                        <h4 className="text-2xl lg:text-3xl group-hover:text-green font-semibold mb-3">
                          {title}
                        </h4>
                        <div className="text-sm lg:text-base">{summary}</div>
                      </div>
                    </Link>
                  </article>
                )
              })}
            </div>
          )}
        </div>
      </section>
    </Layout>
  )
}

export default ProjectLanding

export const pageQuery = graphql`
  query ProjectLandingQuery {
    index: craftProjectsIndexProjectsIndexEntry {
      id
      metaTitle
      metaDescription
      heading: title
      headingTranslated: translatedTitle
      subheading: textPrimary
      text: textSecondary
      image {
        ... on Craft_images_Asset {
          ...imageFragment
        }
      }
    }
    projects: allCraftProjectsDefaultEntry {
      nodes {
        id
        title
        summary
        uri
        postDate
        image {
          ... on Craft_images_Asset {
            ...imageFragment
          }
        }
      }
    }
  }
`
